<template>
  <div class="dropdown">
    <div class="dropdown-toggle" @click="toggleDropdown">
      {{ selectedOption }}
      <i class="fas fa-chevron-down"></i>
    </div>
    <ul class="dropdown-menu" v-show="isOpen">
      <li v-for="option in options" :key="option.value" @click.once="selectOption(option)">
        {{ option.label }}
      </li>
    </ul>
  </div>
</template>

<script>
import { getPayWayList, updateOrder } from '@/api/api'
import config from "@/config"
export default {
  data() {
    return {
      isOpen: false,
      selectedOption: '',
      options: []
    }
  },
  methods: {
    paymentChannel(res) {
      let link = ''
      switch (res) {
        case "IDR_DANA":
          link = '/cashier/IdrDana'
          break;
        case "IDR_LINKAJA":
          link = '/cashier/IdrLinkaja'
          break;
        case "IDR_OVO":
          link = '/cashier/IdrOvo'
          break;
        case "IDR_SHOPEEPAY":
          link = '/cashier/IdrShopeepay'
          break;


        case "IDR_BCA":
          link = '/cashier/IdrBca'
          break;
        case "IDR_BNI":
          link = '/cashier/IdrBni'
          break;
        case "IDR_BRI":
          link = '/cashier/IdrBri'
          break;
        case "IDR_BSI":
          link = '/cashier/IdrBsi'
          break;
        case "IDR_CIMB":
          link = '/cashier/IdrCimb'
          break;

        case "IDR_DANAMON":
          link = '/cashier/IdrDanamon'
          break;
        case "IDR_MANDIRI":
          link = '/cashier/IdrMandiri'
          break;
        case "IDR_MAYBANK":
          link = '/cashier/IdrBank'
          break;
        case "IDR_PERMATA":
          link = '/cashier/IdrPermata'
          break;
      }
      return link
    },
    toggleDropdown() {
      this.isOpen = !this.isOpen;
    },
    selectOption(option) {
      this.updateOrder(option.label)
        .then(result => {
          if (result === true) {
            this.selectedOption = option.label;
            this.isOpen = false;
            this.$router.push(option.link);
          }
        })
    },
    getPayWayList() {
      const that = this
      getPayWayList().then(res => {
        if (res.length === 0) {
          return alert("没有可用的支付方式，请联系商务配置！")
        }

        for (let i = 0; i < res.length; i++) {
          this.options[i] = { value: i, label: res[i], link: this.paymentChannel(res[i]) }
        }
      })
        .catch(res => {
          that.$router.push({ name: config.errorPageRouteName, params: { errInfo: res.msg } })
        });
    },
    updateOrder(wayCode) {
      const that = this
      return new Promise((resolve, reject) => {
        updateOrder(wayCode)
          .then(res => {
            if (res === 'SUCCESS') {
              resolve(true)
            } else {
              resolve(false)
            }
          })
          .catch(error => {
            that.$router.push({ name: config.errorPageRouteName, params: { errInfo: error.msg } });
            reject(error)
          })
      })
    }
  },
  mounted() {
    this.selectedOption = 'Pilih metode pembayaran'
    this.getPayWayList()
  },
}
</script>

<style scoped ="">
.dropdown {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 4px;
}

.dropdown-toggle {
  border-radius: 5px;
  cursor: pointer;
  padding: 10px;
  width: 94%;
  font-size: 10px;
  background-color: #EDEDED;
}

.dropdown-menu {
  border-radius: 5px;
  position: absolute;
  top: 100%;
  left: 3%;
  width: 94%;
  list-style: none;
  padding: 0;
  margin: 4px 0px 0px 0px;
  background-color: #EDEDED;
}

.dropdown-menu li {
  padding: 10px;
  cursor: pointer;
  font-size: 10px;
}

.dropdown-menu li:hover {
  border-radius: 5px;
  background-color: #f5f5f5;
}

@media screen and (min-width: 0px) and (max-width: 750px) {
  .dropdown-toggle {
    width: 100%;
    height: 100px;
    font-size: 40px;
    background-color: #EDEDED;
    border-radius: 15px;
    padding: 30px 20px 20px 20px;
  }

  .dropdown {
    margin-bottom: 10px;
  }

  .dropdown-menu {
    border-radius: 15px;
    position: absolute;
    top: 100%;
    left: 0%;
    width: 100%;
    list-style: none;
    margin: 12px 0px 0px 0px;
    background-color: #EDEDED;
  }

  .dropdown-menu li {
    font-size: 40px;
    padding: 16px;
  }

  .dropdown-menu li:hover {
    border-radius: 15px;
  }

}
</style>

<template>
  <div class="contentWidth" style="background-color:#ffffff; margin: 16px auto;padding-top: 20px;">
    <div>
      <Dropdown />
    </div>
    <div class="header-img1" style="">
      <img style="border-radius: 15px;" src="../../../assets/images/ewallet.png" alt="" />
    </div>
    <div style="width:100%;margin-top: 20px;">
      <div style="font-size: 24px;">Ewallet Saluran Agregasi</div>
    </div>

    <div style="width:100%;margin-top: 16px;">
      <div style="font-size: 16px; color: #8d8d8d;">Silakan pilih metode pembayaran sebelum membayar</div>
    </div>
    <div class="marginBottom" style="font-size: 20px; height: 180px; color: #ffffff;">.</div>
  </div>
</template>

<script>
// import { getPayPackage, getPayOrderInfo, getPayOrderState } from '@/api/api'
// import config from "@/config";
// import CountDownTime from "@/utils/countDownTime";
import Dropdown from '@/views/payway/Idr/dropdown.vue'; // 你的组件路径可能会有所不同
export default {
  components: {
    Dropdown
  },
  data: function () {
    return {
      merchantName: 'BeingFi',  // 付款的商户默认
      avatar: require("../../../assets/images/b.png"), // 商户头像默认
      amount: 1,  // 支付金额默认
      resData: {},
      wxImg: require("../../../assets/images/b.png"), // 微信支付图片
      payOrderInfo: {}, //订单信息
      payApiRes: {}, //支付api返回信息
      intervalOrderState: null, //订单状态定时器
      orderTime: "--:--:--" //剩余时间
    }
  },

  mounted() {
    // this.setPayOrderInfo(true); //获取订单信息 & 调起支付插件
  },

  // methods: {

  //   setPayOrderInfo(isAutoPay) {
  //     const that = this
  //     getPayOrderInfo().then(res => {
  //       that.payOrderInfo = res
  //       that.checkOrderState()
  //       CountDownTime.initCountdown(that.payOrderInfo.ext.ExpiredTimeStamp, (time) => {
  //         that.orderTime = time
  //       })
  //       if (isAutoPay) {
  //         that.pay()
  //       }
  //     })
  //     // .catch(res => {
  //     //   that.$router.push({ name: config.errorPageRouteName, params: { errInfo: res.msg } })
  //     // });
  //   },

  //   pay: function () {

  //     let that = this;
  //     getPayPackage(this.amount).then(res => {

  //       //订单创建异常
  //       if (res.code != '0') {
  //         return alert(res.msg);
  //       }

  //       if (res.data.orderState != 1) { //订单不是支付中，说明订单异常
  //         return alert(res.data.errMsg);
  //       }
  //       console.log("请求返回参数", res.data)
  //       that.payApiRes = res.data
  //     }).catch(res => {
  //       that.$router.push({ name: config.errorPageRouteName, params: { errInfo: res.msg } })
  //     });
  //   },
  //   onCopy() {
  //     alert('Disalin dengan sukses');
  //   },
  //   // 检查订单状态
  //   checkOrderState() {
  //     let that = this;
  //     //定时器
  //     that.intervalOrderState = setInterval(function () {
  //       getPayOrderState().then(res => {
  //         if (res === 2) { //订单成功状态
  //           clearInterval(that.intervalOrderState);
  //           location.href = that.payApiRes.returnUrl;
  //         }
  //         if (res === 3) { //订单失败状态
  //           clearInterval(that.intervalOrderState);
  //           that.$router.push({ name: config.errorPageRouteName, params: { errInfo: "Order Failure !!!" } })
  //         }
  //         if (res === 6) { //订单过期状态
  //           clearInterval(that.intervalOrderState);
  //           that.$router.push({ name: config.errorPageRouteName, params: { errInfo: "Order Expired !!!" } })
  //         }
  //       }).catch(res => {
  //         clearInterval(that.intervalOrderState);
  //         that.$router.push({ name: config.errorPageRouteName, params: { errInfo: res.msg } })
  //       })
  //     }, 5000); //5秒检查一次
  //   }
  // }
}
</script>
<style lang="css" scoped>@import './../pay.css';</style>